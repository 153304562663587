import * as Sentry from '@sentry/vue';


export default defineNuxtPlugin((nuxtApp) => {

  const config = useRuntimeConfig();
  const router = useRouter();

  if (!['dev', 'prod'].includes(config.public.stage)) return;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.stage,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: config.public.stage === 'prod' ? 0.2 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  return { provide: { sentry: Sentry } };
});