import revive_payload_client_YTGlmQWWT5 from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/tmp/seed/source/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/tmp/seed/source/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/tmp/seed/source/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/tmp/seed/source/packages/app/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/tmp/seed/source/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_oeUu8xcI5w from "/tmp/seed/source/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/tmp/seed/source/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/tmp/seed/source/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/tmp/seed/source/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_client_zXNDa0wxFH from "/tmp/seed/source/packages/app/plugins/firebase.client.ts";
import sentry_client_shVUlIjFLk from "/tmp/seed/source/packages/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  chunk_reload_client_SeG0EjL5Ec,
  firebase_client_zXNDa0wxFH,
  sentry_client_shVUlIjFLk
]