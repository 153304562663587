import { default as telemetryzZGoPD2PDkMeta } from "/tmp/seed/source/packages/app/pages/admin/telemetry.vue?macro=true";
import { default as admin2GY0oY2YggMeta } from "/tmp/seed/source/packages/app/pages/admin.vue?macro=true";
import { default as billingGlkYmZWvBUMeta } from "/tmp/seed/source/packages/app/pages/billing.vue?macro=true";
import { default as complete_45registrationkQY0BM1s6TMeta } from "/tmp/seed/source/packages/app/pages/complete-registration.vue?macro=true";
import { default as contactP7Qx96qb2sMeta } from "/tmp/seed/source/packages/app/pages/contact.vue?macro=true";
import { default as developers802XzqEFGpMeta } from "/tmp/seed/source/packages/app/pages/developers.vue?macro=true";
import { default as forgot_45passwordqY0IdB4r4JMeta } from "/tmp/seed/source/packages/app/pages/forgot-password.vue?macro=true";
import { default as helpMuK2w9Zh5eMeta } from "/tmp/seed/source/packages/app/pages/help.vue?macro=true";
import { default as indexIwL0tEgHvqMeta } from "/tmp/seed/source/packages/app/pages/index.vue?macro=true";
import { default as linesK24vAyKnMMMeta } from "/tmp/seed/source/packages/app/pages/lines.vue?macro=true";
import { default as loginK1qTU92BdwMeta } from "/tmp/seed/source/packages/app/pages/login.vue?macro=true";
import { default as privacyiAKHPVDJnNMeta } from "/tmp/seed/source/packages/app/pages/privacy.vue?macro=true";
import { default as registerufppk7FswDMeta } from "/tmp/seed/source/packages/app/pages/register.vue?macro=true";
import { default as subscriptionSi08PIv0ZJMeta } from "/tmp/seed/source/packages/app/pages/subscription.vue?macro=true";
import { default as team4c56P5GuIaMeta } from "/tmp/seed/source/packages/app/pages/team.vue?macro=true";
import { default as terms80jUvhxaUtMeta } from "/tmp/seed/source/packages/app/pages/terms.vue?macro=true";
import { default as ticketsXfKvsJUFqcMeta } from "/tmp/seed/source/packages/app/pages/tickets.vue?macro=true";
import { default as update_45password4FVtUcZO8UMeta } from "/tmp/seed/source/packages/app/pages/update-password.vue?macro=true";
export default [
  {
    name: admin2GY0oY2YggMeta?.name ?? "admin",
    path: admin2GY0oY2YggMeta?.path ?? "/admin",
    meta: admin2GY0oY2YggMeta || {},
    alias: admin2GY0oY2YggMeta?.alias || [],
    redirect: admin2GY0oY2YggMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: telemetryzZGoPD2PDkMeta?.name ?? "admin-telemetry",
    path: telemetryzZGoPD2PDkMeta?.path ?? "telemetry",
    meta: telemetryzZGoPD2PDkMeta || {},
    alias: telemetryzZGoPD2PDkMeta?.alias || [],
    redirect: telemetryzZGoPD2PDkMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/admin/telemetry.vue").then(m => m.default || m)
  }
]
  },
  {
    name: billingGlkYmZWvBUMeta?.name ?? "billing",
    path: billingGlkYmZWvBUMeta?.path ?? "/billing",
    meta: billingGlkYmZWvBUMeta || {},
    alias: billingGlkYmZWvBUMeta?.alias || [],
    redirect: billingGlkYmZWvBUMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/billing.vue").then(m => m.default || m)
  },
  {
    name: complete_45registrationkQY0BM1s6TMeta?.name ?? "complete-registration",
    path: complete_45registrationkQY0BM1s6TMeta?.path ?? "/complete-registration",
    meta: complete_45registrationkQY0BM1s6TMeta || {},
    alias: complete_45registrationkQY0BM1s6TMeta?.alias || [],
    redirect: complete_45registrationkQY0BM1s6TMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/complete-registration.vue").then(m => m.default || m)
  },
  {
    name: contactP7Qx96qb2sMeta?.name ?? "contact",
    path: contactP7Qx96qb2sMeta?.path ?? "/contact",
    meta: contactP7Qx96qb2sMeta || {},
    alias: contactP7Qx96qb2sMeta?.alias || [],
    redirect: contactP7Qx96qb2sMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: developers802XzqEFGpMeta?.name ?? "developers",
    path: developers802XzqEFGpMeta?.path ?? "/developers",
    meta: developers802XzqEFGpMeta || {},
    alias: developers802XzqEFGpMeta?.alias || [],
    redirect: developers802XzqEFGpMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordqY0IdB4r4JMeta?.name ?? "forgot-password",
    path: forgot_45passwordqY0IdB4r4JMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordqY0IdB4r4JMeta || {},
    alias: forgot_45passwordqY0IdB4r4JMeta?.alias || [],
    redirect: forgot_45passwordqY0IdB4r4JMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: helpMuK2w9Zh5eMeta?.name ?? "help",
    path: helpMuK2w9Zh5eMeta?.path ?? "/help",
    meta: helpMuK2w9Zh5eMeta || {},
    alias: helpMuK2w9Zh5eMeta?.alias || [],
    redirect: helpMuK2w9Zh5eMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexIwL0tEgHvqMeta?.name ?? "index",
    path: indexIwL0tEgHvqMeta?.path ?? "/",
    meta: indexIwL0tEgHvqMeta || {},
    alias: indexIwL0tEgHvqMeta?.alias || [],
    redirect: indexIwL0tEgHvqMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: linesK24vAyKnMMMeta?.name ?? "lines",
    path: linesK24vAyKnMMMeta?.path ?? "/lines",
    meta: linesK24vAyKnMMMeta || {},
    alias: linesK24vAyKnMMMeta?.alias || [],
    redirect: linesK24vAyKnMMMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/lines.vue").then(m => m.default || m)
  },
  {
    name: loginK1qTU92BdwMeta?.name ?? "login",
    path: loginK1qTU92BdwMeta?.path ?? "/login",
    meta: loginK1qTU92BdwMeta || {},
    alias: loginK1qTU92BdwMeta?.alias || [],
    redirect: loginK1qTU92BdwMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacyiAKHPVDJnNMeta?.name ?? "privacy",
    path: privacyiAKHPVDJnNMeta?.path ?? "/privacy",
    meta: privacyiAKHPVDJnNMeta || {},
    alias: privacyiAKHPVDJnNMeta?.alias || [],
    redirect: privacyiAKHPVDJnNMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: registerufppk7FswDMeta?.name ?? "register",
    path: registerufppk7FswDMeta?.path ?? "/register",
    meta: registerufppk7FswDMeta || {},
    alias: registerufppk7FswDMeta?.alias || [],
    redirect: registerufppk7FswDMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: subscriptionSi08PIv0ZJMeta?.name ?? "subscription",
    path: subscriptionSi08PIv0ZJMeta?.path ?? "/subscription",
    meta: subscriptionSi08PIv0ZJMeta || {},
    alias: subscriptionSi08PIv0ZJMeta?.alias || [],
    redirect: subscriptionSi08PIv0ZJMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: team4c56P5GuIaMeta?.name ?? "team",
    path: team4c56P5GuIaMeta?.path ?? "/team",
    meta: team4c56P5GuIaMeta || {},
    alias: team4c56P5GuIaMeta?.alias || [],
    redirect: team4c56P5GuIaMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/team.vue").then(m => m.default || m)
  },
  {
    name: terms80jUvhxaUtMeta?.name ?? "terms",
    path: terms80jUvhxaUtMeta?.path ?? "/terms",
    meta: terms80jUvhxaUtMeta || {},
    alias: terms80jUvhxaUtMeta?.alias || [],
    redirect: terms80jUvhxaUtMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: ticketsXfKvsJUFqcMeta?.name ?? "tickets",
    path: ticketsXfKvsJUFqcMeta?.path ?? "/tickets",
    meta: ticketsXfKvsJUFqcMeta || {},
    alias: ticketsXfKvsJUFqcMeta?.alias || [],
    redirect: ticketsXfKvsJUFqcMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/tickets.vue").then(m => m.default || m)
  },
  {
    name: update_45password4FVtUcZO8UMeta?.name ?? "update-password",
    path: update_45password4FVtUcZO8UMeta?.path ?? "/update-password",
    meta: update_45password4FVtUcZO8UMeta || {},
    alias: update_45password4FVtUcZO8UMeta?.alias || [],
    redirect: update_45password4FVtUcZO8UMeta?.redirect,
    component: () => import("/tmp/seed/source/packages/app/pages/update-password.vue").then(m => m.default || m)
  }
]