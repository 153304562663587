
type Type = 'success' | 'error' | 'warning' | 'info' | 'loading';

export const title    = ref('');
export const type     = ref<Type>('info');
export const visible  = ref(false);
export const closable = ref(true);

export function useNotification(opts: {
  timeout?:   number | null,
  title?:     string,
  closable?:  boolean,
  type?:      Type,
}) {

  return {

    show: () => {
      type.value     = opts.type ?? 'info';
      title.value    = opts.title || '';
      closable.value = opts.closable === undefined ? true : opts.closable;

      if (opts.timeout) {
        setTimeout(() => {
          visible.value = false;
        }, opts.timeout);
      }

      visible.value = true;
    },

    hide: () => {
      visible.value = false;
    }

  }

}

export function showNotification(type: Type, title: string, timeout?: number | null, closable?: boolean) {
  useNotification({ type, closable, title, timeout }).show();
}
