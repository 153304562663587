<script lang="ts" setup>
</script>

<template>

  <span class="loader"></span>

</template>

<style scoped>

  .loader {
    border-width: 3px;
    border-style: solid;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    @apply animate-spin;
    animation-duration: 1s;
  }

</style>
