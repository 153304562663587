<script lang="ts" setup>
import omit from 'lodash.omit';


const store = useStore();


useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} — Simpuppet` : 'Simpuppet';
  }
});


const router = useRouter();

if (process.client) {

  /**
   * When the user navigates to a new page, we restore
   * the active number, conversation and search query based
   * on the URL parameters.
   */
  const query = router.currentRoute.value.query;
  if (query.n) store.activeNumber = query.n;
  if (query.s) store.numberSearch = decodeURIComponent(query.s);


  /**
   * Update the URL hash when the active number or conversation changes.
   * Used so the user can share/reload a page with a particular
   * conversation & number or even search query.
   */
  watch(() => [
    store.activeNumber,
    store.numberSearch,
  ], () => {

    router.replace({
      query: {
        ...(store.activeNumber.length > 0 ? { n: store.activeNumber } : {}),
        ...(store.numberSearch.length > 0 ? { s: encodeURIComponent(store.numberSearch) } : {}),
      }
    });

  });

}

</script>
<template>

  <div
    v-if="useStore().loadingPage"
    class="
      w-full h-full
      z-50
      flex flex-col justify-center items-center
      fixed left-0 top-0 bg-gray-50 dark:bg-gray-900 animate-fade-in">

    <Logo class="h-6" />

    <Loader class="mt-6 w-5 h-5 border-gray-300" />

  </div>

  <Error v-if="store.showErrorPage" />

  <template v-else>

    <ClientOnly>
      <NuxtLayout>
        <NuxtLoadingIndicator
          v-if="!useStore().loadingPage"
          color="#facc15"
          :height="4"
          />
          <NuxtPage />
      </NuxtLayout>

      <NotificationsContainer />

    </ClientOnly>

  </template>

</template>
