import { downloadFile } from "@/utils/fileDownload";
import { type DeveloperSettings } from "@core/db/schema";
import type { Code, JSendFormat } from "@simpuppet/shared/codes";
import type { Invoice, Message, PaymentCard, SIM } from "@simpuppet/shared/types";
import dayjs from "dayjs";

export default async function useApi<T = Record<string, any>>(
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  endpoint: string,
  body = {},
  opts: {
    controller?: AbortController,
    noNotificationOnError: boolean,
    maxAttempt: number,
  } = {
    noNotificationOnError: false,
    maxAttempt: 3,
  },
  attempt = 0,
): Promise<JSendFormat<T>> {

  const config = useRuntimeConfig();
  const store = useStore();
  let resStatusCode: number;

  if (!store.idToken) await store.refreshIdToken();

  const options: Record<string, any> = {
    method,
    headers: {
      Authorization: `Bearer ${store.idToken}`,
    },
    ...(opts?.controller && { signal: opts.controller.signal })
  };

  if (method === 'POST' || method === 'PUT') {
    options.headers['Content-Type'] = 'application/json;charset=utf-8';
    options.body = JSON.stringify(body);
  }

  try {

    const res = await fetch(`${config.public.apiUrl}/${endpoint}`, options);
    if (res.ok) {
      const json = await res.json() as JSendFormat<T>;
      return json;
    }
    else {

      resStatusCode = res.status;
      /**
       * If we can parse the response as a JSON, we throw the JSON.
       * Otherwise, we throw the response as a text.
       */

      let errorToThrow;
      try {
        const json = await res.json() as JSendFormat<T>;
        errorToThrow = json;
      } catch (err) { errorToThrow = Error(await res.text()); }

      throw errorToThrow;
    }

  } catch (err) {

    const isServerError = resStatusCode!?.toString().startsWith('5');
    const allAttemptsFailed = attempt >= opts.maxAttempt;

    if (isServerError || allAttemptsFailed) {
      if (store.loadingPage)  {
        store.showErrorPage = true;
        store.loadingPage = false;
      } else if (!opts.noNotificationOnError) {
        showNotification(
          'error',
          'An unexpected error occurred. Please try again later.',
          null,
          true,
        );
      }

    }

    /**
     * Only retry if the error is 403.
     */
    if (!allAttemptsFailed && resStatusCode! === 403) {
      await store.refreshIdToken();
      return await useApi(method, endpoint, body, {
        controller: opts?.controller,
        noNotificationOnError: opts?.noNotificationOnError,
        maxAttempt: opts?.maxAttempt,
      }, attempt + 1);
    }

    throw err;

  }

}


export const API = {


  saveEmailForwarding: async (payload: { email: string, senderFilters: string }) => {
    await useApi('PUT', 'developers/email-fowarding', payload);
  },

  getModemRotationMode: async () => {
    const { data } = await useApi<{ mode: 'auto' | 'forced' | 'disabled' }>('GET', 'admin/rotation-mode');
    return data?.mode!;
  },

  updateModemRotationMode: async (mode: 'auto' | 'forced' | 'disabled') => {
    await useApi('PUT', 'admin/rotation-mode', { mode });
  },

  getSignalTelemetry: async (modemId: number, afterDate?: Date) => {

    const params = new URLSearchParams({
      ...(modemId && { modemId: modemId.toString() }),
      ...(afterDate && { afterDate: afterDate.toISOString() }),
    });

    const { data } = await useApi<{ signals: number[], timestamps: string[] }>('GET', `admin/telemetry/signal?${params.toString()}`);
    return data ?? { signals: [], timestamps: [] };
  },

  downloadMessagesExportCsv: async (payload: {
    startDate?: string,
    endDate?: string,
    senders?: string,
  }) => {

    const params = new URLSearchParams({
      ...(payload.startDate && { startDate: payload.startDate }),
      ...(payload.endDate && { endDate: payload.endDate }),
      ...(payload.senders && { senders: payload.senders }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const endpoint = `messages/csv?${params.toString()}`;
    const { data } = await useApi<{ csv: string }>('GET', endpoint);


    if (!data) throw Error('No data returned from the server.');

    const timestamp = dayjs().format('YYYY-MM-DD_HH-mm-ss');
    console.log(timestamp);
    downloadFile(new Blob([data.csv], { type: 'text/csv;charset=utf-8;' }), `messages-${timestamp}.csv`);
  },

  getPreviewExportCount: async (payload: {
    startDate?: string,
    endDate?: string,
    senders?: string,
  }) => {

    const params = new URLSearchParams({
      ...(payload.startDate && { startDate: payload.startDate }),
      ...(payload.endDate && { endDate: payload.endDate }),
      ...(payload.senders && { senders: payload.senders }),
    });
    const endpoint = `messages/export-preview?${params.toString()}`;
    const { data } = await useApi<{ count: number }>('GET', endpoint);
    return data?.count!;
  },

  getRegistrationStatus: async () => {

    const store = useStore();
    let accountExists = false;

    if (!store.registrationStatusCompleted) {
      const res = await useApi<{
        completed: boolean,
        accountExists: boolean,
      }>('GET', 'account/registration-status');
      store.registrationStatusCompleted = res.data?.completed ?? false;
      accountExists = res.data?.accountExists ?? false;
    }

    return { completed: store.registrationStatusCompleted, accountExists };
  },

  updateAccount: async (payload: { discordUsername: string }) => {
    await useApi('PUT', 'account', {
      discordUsername: payload.discordUsername,
    });
  },

  updateWebhook: async (url: string, senderFilters = '') => {
    await useApi('PUT', 'developers/webhook', { webhookUrl: url, webhookSenderFilters: senderFilters });
  },

  getDeveloperSettings: async () => {
    const res = await useApi<{ config: DeveloperSettings }>('GET', 'developers/settings');
    return res.data?.config!;
  },

  refreshApiKey: async () => {
    const res = await useApi<{ apiKey: string }>('POST', 'developers/api-key');
    return res.data?.apiKey!;
  },

  getProratedCost: async (amount: number) => {
    const { data } = await useApi<{ proratedCost: number | null }>('GET', `subscriptions/prorated-cost?amount=${amount}`);
    return data?.proratedCost!;
  },

  verifyDiscountCode: async (code: string) => {
    const { data } = await useApi<{
      validation: { valid: boolean, reason: Code },
      discountedPricePerLine: number,
    }>('POST', 'subscriptions/verify-discount', { code });
    return data!;
  },

  undoDowngradeSubscription: async (numbersToUndo: string[]) => {
    const res = await useApi<{
      resubscribedSims: SIM[],
    }>('POST', 'subscriptions/undo-downgrade', numbersToUndo);
    return res.data?.resubscribedSims ?? [];
  },

  downgradeSubscription: async (numbersToCancel: string[]) => {
    const res = await useApi<{
      cancelledSims: SIM[],
    }>('POST', 'subscriptions/downgrade', numbersToCancel);
    return res.data?.cancelledSims ?? [];
  },

  upgradeSubscription: async (amount: number, discountCode?: string) => {
    const res = await useApi<null>('POST', 'subscriptions/upgrade', {
      amount,
      discountCode,
    }, {
      maxAttempt: 0,
      noNotificationOnError: true,
    });
    return res;
  },

  createAccount: async (payload: { discordUsername: string }) => {
    await useApi('POST', 'account', {
      discordUsername: payload.discordUsername,
    });
  },

  getMessages: async (simNumber?: string, page?: number) => {
    // @ts-ignore
    const params = new URLSearchParams({
      ...(simNumber && { simNumber }),
      ...(page && { page: page.toString() }),
    });
    const endpoint = `messages?${params.toString()}`;
    const res = await useApi<{ messages: Message[] }>('GET', endpoint);
    const { messages = [] } = res.data ?? {};
    return messages;
  },

  getSetupIntentSecret: async () => {
    const res = await useApi('GET', 'stripe/setup-intent-secret');
    const { clientSecret } = res.data ?? {};
    return clientSecret;
  },

  notifyActivity: async () => {
    await useApi('POST', 'account/activity');
  },

  setPaymentCardAsDefault: async (id: string) => {
    const store = useStore();
    await useApi('POST', `payment-cards/${id}/default`);
    store.setPaymentCardAsDefault(id);
  },

  getPaymentCard: async (id: string) => {
    const res = await useApi<{ card: PaymentCard }>('GET', `payment-cards/${id}`);
    return res.data?.card;
  },

  getInvoices: async () => {
    const res = await useApi<{ invoices: Invoice[] }>('GET', 'stripe/invoices');
    return res.data?.invoices ?? [];
  },

  deleteMessage: async (id: number) => {
    await useApi('DELETE', `messages/${id}`);
  },

  loadPricePerLineIntoStore: async () => {
    const store = useStore();
    const res = await useApi<{ pricePerLine: number }>('GET', 'subscriptions/price-per-line');
    store.pricePerLine = res.data?.pricePerLine!;
  },

  loadHasSubscriptionIntoStore: async () => {
    const store = useStore();
    const res = await useApi<{ hasSubscription: boolean }>('GET', 'subscriptions/has-subscription');
    store.hasSubscription = Boolean(res.data?.hasSubscription);
  },

  loadPaymentCardsIntoStore: async () => {
    const store = useStore();
    const res = await useApi<{ cards: PaymentCard[] }>('GET', 'payment-cards');
    const { cards = [] } = res.data ?? {};
    store.paymentCards = cards;
  },

  loadNextRotationDateIntoStore: async () => {
    const store = useStore();
    const res = await useApi<{ nextRotationDate: string }>('GET', 'sims/next-rotation-date');
    if (!res.data?.nextRotationDate) return;
    store.nextRotationDate = dayjs(res.data?.nextRotationDate!).toDate();
  },

  loadSimsIntoStore: async () => {
    const store = useStore();
    const res = await useApi<{ sims: SIM[] }>('GET', 'sims');
    const { sims = [] } = res.data ?? {};
    store.allSims = sims;
  },

  turnOnSim: async (number: string) => {
    const store = useStore();
    const sim = store.allSims.find((sim) => sim.number === number);
    if (!sim) return;
    await useApi('POST', `sims/${number}/enable`);

    store.allSims = store.allSims.map((s) => {
      if (s.number === number) return { ...s, status: 'PREPARING' };
      else if (s.modemId === sim.modemId && s.modemPort.split('.')[0] === sim.modemPort.split('.')[0] && s.number !== number) {
        return { ...s, status: 'INACTIVE' };
      }
      else return s;
    });
  }

};