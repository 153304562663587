import { JSendFormat } from "@simpuppet/shared/codes";
import mitt from "mitt";

type Events = {
  'subscription-upgrade': JSendFormat,
};

const emitter = mitt<Events>();

export const useEvent = () => {
  return emitter;
}