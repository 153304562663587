export default defineAppConfig({
  ui: {
    primary: 'zinc',
    gray: 'zinc',

    input: {
      base: 'disabled:shadow-none transition-all',
      rounded: 'rounded-[4px]',
      padding: {
        sm: 'py-2'
      },
      variant: {
        outline: 'bg-white dark:bg-gray-900'
      },
    },

    badge: {
      rounded: 'rounded-[4px]',
      default: {
        size: 'xs',
      },
    },

    tooltip: {
      background: 'bg-gray-900 dark:bg-white',
      color: 'dark:text-gray-900 text-white',
      ring: 'ring-0',
      base: `overflow-visible whitespace-normal h-auto py-2 px-4`,
    },

    skeleton: {
      background: 'bg-gray-200 dark:bg-gray-700',
      rounded: 'rounded-[4px]'
    },

    modal: {
      margin: 'mx-4',
      padding: 'px-4 py-0',
      rounded: 'rounded-[4px]',
      ring: 'ring-1 ring-gray-200 dark:ring-gray-700',
      shadow: 'shadow-lg',
      width: '!max-w-[340px]',
      overlay: {
        background: 'bg-white/90 dark:bg-black/90 backdrop-blur-sm',
      }
    },

    table: {
      wrapper: 'border border-gray-200 dark:border-gray-700 rounded-md',
      thead: 'bg-gray-50 dark:bg-gray-900',
      divide: 'divide-gray-200 dark:divide-gray-700',
      tr: {
        base: 'bg-white dark:bg-gray-900',
      },
      th: {
        font: 'font-medium',
        padding: 'px-4 py-2.5'
      },
      td: {
        padding: 'px-4 py-2'
      }
    },

    formGroup: {
      error: 'text-xs mt-1 text-red-700 dark:text-red-400',
      help: 'text-xs mt-1 text-gray-500 dark:text-gray-400',
    },

    alert: {
      rounded: 'rounded-[4px]',
      padding: 'py-1.5 px-4',
      // description: 'text-xs mt-0',
    },

    toggle: {
      active: '!bg-yellow-400',
      icon: {
        on: '!text-yellow-400',
      }
      // container: {
      //   base: 'bg-amber-500'
      // }
    },

    checkbox: {
      base: 'h-[14px] cursor-pointer w-[14px] shadow-inner',
      background: 'bg-white dark:bg-gray-700',
      border: 'border border-gray-300 dark:border-gray-600',
      rounded: 'rounded-[2px]',
    },

    buttonGroup: {
      shadow: 'shadow-none',
    },

    button: {
      base: `transition-all relative inline-flex justify-center disabled:opacity-50 disabled:!drop-shadow-none disabled:!shadow-none`,
      rounded: 'rounded-[4px]',
      default: {
        color: 'black',
      },
      padding: {
        sm: 'py-2'
      },
      icon: {
        size: {
          xs: 'h-3.5 w-3.5',
          sm: 'h-4 w-4',
        },
      },
      color: {
        white: {
          solid: 'active:drop-shadow-none active:shadow-inner active:bg-gray-100 disabled:bg-gray-100'
        }
      },
    }
  }
});
