import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, onAuthStateChanged } from "firebase/auth";


export default defineNuxtPlugin(nuxtApp => {

  const config = useRuntimeConfig();

  if (!config.public.firebaseConfig) return;

  const firebaseConfig = JSON.parse(config.public.firebaseConfig);

  const app = initializeApp(firebaseConfig);
  console.log('initialized firebase');
  const auth = getAuth(app);

  if (config.public.stage.startsWith('local')) {
    console.info('Using auth emulator...');
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  }


  onAuthStateChanged(auth, async (user) => {

    const store = useStore();
    const route = useRoute();

    if (!user) {
      if (route.path === '/register' || route.path === '/terms') return;
      return navigateTo('/login');
    }

    try {

      store.currentUser = user;
      store.emailVerified = user.emailVerified;
      store.idToken = await user.getIdToken() ?? '';
      const { claims } = await user.getIdTokenResult();
      store.userIsAdmin = claims.admin as boolean;


      if (process.dev) console.log('[TOKEN]', store.idToken);

    } catch (error) {
      console.error('[ERROR]', error);
    }

  });

  nuxtApp.vueApp.provide('auth', auth);
  nuxtApp.provide('auth', auth);

});
