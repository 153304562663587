import { getAuth, type User } from 'firebase/auth';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { type Message, type SIM, type PaymentCard } from '@shared/types';
import { type JSendFormat } from "@simpuppet/shared/codes";


// TODO: Move this to @simpuppet/shared
const ITEMS_PER_PAGE = 20;

export const useStore = defineStore('main', {

  state: () => ({

    registrationStatusCompleted: false,

    pricePerLine: null as unknown as number,
    stripeScriptLoaded: false,

    loadingPage: true,
    showErrorPage: false,
    currentUser: null as User | null,
    emailVerified: false,
    idToken: '',
    loggingOutFlag: false,

    previousRoutePath: '',
    userIsAdmin: false,

    socket: null as WebSocket | null,
    nextRotationDate: null as Date | null,
    websocketStatus: 'CONNECTING' as 'RECONNECTING' | 'CONNECTING' | 'CONNECTED' | 'DISCONNECTED',

    hasSubscription: false,

    nextPageFor: {} as Record<string, number | null>,
    paymentCards:     [] as PaymentCard[],

    // Those fields are supposed to be filterered
    // later down the line, hence the name 'allXXX'.
    allSims:          [] as SIM[],
    allMessages:      [] as Message[],

    // Filters
    activeNumber: '',
    numberSearch: '',

  }),

  getters: {

    websocketReady: (state) => state.websocketStatus === 'CONNECTED',

    isLoggedIn: (state) => !!state.currentUser && !state.loggingOutFlag,
    defaultPaymentCard: (state) => state.paymentCards.find((card) => card.isDefault),

    sims: (state) => {
      const statusOrder = ['ACTIVE', 'PREPARING', 'INACTIVE', 'ERROR'];
      return state.allSims.filter(sim => sim.number.includes(state.numberSearch));
        // .sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));
    },

    activeSim: (state) => state.allSims.find(sim => sim.number === state.activeNumber),

    messages: (state) => {

      let messages = state.allMessages;

      // Filter by active number
      if (state.activeNumber) {
        messages = messages.filter(m => {
          return  m.recipient === state.activeNumber ||
                  m.sender === state.activeNumber;
        });
      }

      // TODO: Filter by search
      // TODO: Sort by timestamp
      // messages = messages.sort((a, b) => a.timestamp - b.timestamp);

      // Hacky way to make sure the array is reactive
      return [...messages];
    }

  },

  actions: {

    async logout() {
      this.loggingOutFlag = true;
      await getAuth().signOut();
      this.socket?.close();

      showNotification(
        'success',
        'You have been logged out successfully.',
        4000
      );

      this.currentUser = null;
      this.loggingOutFlag = false;
      // Reset all data
      this.previousRoutePath = '';
      this.paymentCards     = [];
      this.allSims          = [];
      this.allMessages      = [];
      this.activeNumber     = '';
      this.numberSearch     = '';
      this.registrationStatusCompleted = false;

    },

    async refreshIdToken() {
      this.idToken = await this.currentUser?.getIdToken(true) ?? '';
      return this.idToken;
    },

    deletePaymentCard() {
      this.paymentCards = this.paymentCards.filter((card) => card.isDefault);
    },

    setPaymentCardAsDefault(id: string) {
      this.paymentCards = this.paymentCards.map((card) => {
        if (card.id === id) {
          return { ...card, isDefault: true };
        } else if (card.isDefault) {
          return { ...card, isDefault: false };
        } else {
          return card;
        }
      });
    },

    waitForWebsocketReady() {
      return new Promise((resolve) => {
        watch(() => this.websocketReady, () => {
          if (this.websocketReady) resolve(null);
        }, { immediate: true });
      });
    },

    /**
     * Called when a websocket event is received
     * @see useWebsocket.ts
     */
    async onWebsocketEvent(
      eventName: 'message' | 'subscription-upgrade' | 'sims-status',
      payload: JSendFormat,
    ) {

      const eventbus = useEvent();
      const {
        status,
        data,
        message,
        code,
      } = payload;

      if (eventName === 'sims-status') {
        const { preparing = [], failed = [], active = [], inactive = [], nextRotationDate } = data;

        this.nextRotationDate = nextRotationDate;

        this.allSims = this.allSims.map(sim => {
          if (active.includes(sim.number))    sim.status = 'ACTIVE';
          if (preparing.includes(sim.number)) sim.status = 'PREPARING';
          if (inactive.includes(sim.number))  sim.status = 'INACTIVE';
          if (failed.includes(sim.number))    sim.status = 'ERROR';
          return sim;
        });

        return;
      }

      if (eventName === 'message') {
        const { message } = data;
        this.allMessages.push(message);
        return;
      }

      if (eventName === 'subscription-upgrade') {
        eventbus.emit('subscription-upgrade', {
          status,
          code,
          message,
          data,
        });
        return;
      }

      throw new Error(`Unknown websocket event: ${eventName}`);
    },

    updateSimInList(sim: SIM) {
      const simIndex = this.allSims.findIndex(s => s.number === sim.number);
      if (simIndex !== -1) this.allSims[simIndex] = sim;
    },

    /**
     * Calculate the current page for a given number
     * based on all the already loaded messages.
     * @param number
     * @returns
     */
    calculateCurrentPage(number: string) {
      return number === 'all' ?
          Math.floor(this.allMessages.length / ITEMS_PER_PAGE) - 1:
          Math.floor(this.messages.filter(m => m.recipient === number || m.sender === number).length / ITEMS_PER_PAGE) - 1;
    },

    /**
     * If the messages we received for a call is less than ITEMS_PER_PAGE,
     * it means we reached the end of the pagination ~for that number~.
     * This is symbolized with the nextPageFor[number] = null.
     * @param number
     * @param messages
     */
    calculateNextPage(number: string, messages: Message[]) {
      this.nextPageFor[number] = messages.length < ITEMS_PER_PAGE ? null : this.nextPageFor[number] ?? 0 + 1;
    },

    /**
     * Called only for each number and for the inbox (ie number = 'all').
     * Even tho it's purpose seems redundant compared to loadMoreMessages,
     * this function has it's utility in allowing us to treat pagination
     * caching (ie not making useless calls if messages are already loaded)
     * seperatly from the actual pagination when we load more messages.
     * @param number
     * @returns
     */
    async loadInitialMessages(number: string) {

      const currentPage = this.calculateCurrentPage(number);

      /**
       * If the current page is greater than 0, in case
       * where for example we loaded the inbox messages
       * before loading a specific number's messages,
       * we don't run a initial request for that number
       * as the initial messages are already loaded.
       *
       * NOTE: This behavior is not extended to the inbox (ie number = 'all')
       * (hence the number !== 'all' check) because we want to
       * make sure the inbox contains all messages and not only
       * those that were previously loaded for specific numbers.
       * TODO: Make this clearer
       */
      if (currentPage >= 0 && number !== 'all') return;

      const messages = await API.getMessages();
      this.calculateNextPage(number, messages);

      const uniqueMessages = messages.filter(m => !this.allMessages.find(m2 => m2.id === m.id));
      this.allMessages.unshift(...uniqueMessages.reverse());
    },

    /**
     * As the name implies it loads more messages for a given number,
     * when the user scrolls.
     */
    async loadMoreMessages(number: string | 'all') {

      let page = this.nextPageFor[number];

      // If the page is null, it means we reached the end of the pagination.
      if (!page) return;

      const currentPage = this.calculateCurrentPage(number);
      if (currentPage >= page) return;

      const messages = await API.getMessages(number, page);
      this.calculateNextPage(number, messages);

      // remove duplicates
      const uniqueMessages = messages.filter(m => !this.allMessages.find(m2 => m2.id === m.id));
      this.allMessages.unshift(...uniqueMessages.reverse());
    },

    async deleteMessage(messageId: number) {
      this.allMessages = this.allMessages.filter(m => m.id !== messageId);
      await API.deleteMessage(messageId);
    }

  },

});


if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
